import React, { useState } from "react";
import "./Settings.sass";
import Bots from "./Bots";
import Game from "./Game";
import MyButton from "@components/UI/MyButton/MyButton";
import event from "../../../event";
import gameState from '@store/game';

function Settings() {
  let [tab, setTab] = useState("game");
  let [enabled, setEnabled] = useState(false);

  let header = enabled ? "Настройки" : "Настройки: по умолчанию";

  function ShowTab() {
    if (!enabled) return null;

    if (tab === "game") {
      return <Game />;
    } else if (tab === "bots") {
      return <Bots />;
    }
  }

  return (
    <div className="settings-game-section">
      <h4
        onClick={() => setEnabled(!enabled)}
        className="sesettings-game-header"
      >
        {header}
      </h4>

      {enabled ? (
        <div className="tab-list">
          <div
            onClick={() => setTab("game")}
            className={`tab ${tab === "game" ? "active" : ""}`}
          >
            Игра
          </div>
          <div
            onClick={() => setTab("bots")}
            className={`tab ${tab === "bots" ? "active" : ""}`}
          >
            Боты
          </div>
        </div>
      ) : null}

      <ShowTab />

      {enabled ? (
        <MyButton
          name="save-config"
          type="button"
          classes="save-config"
          value="Сохранить"
          // disabled={submitDisabled()}
          handler={() => gameState.saveConfig()}
        />
      ) : null}
    </div>
  );
}

export default Settings;
