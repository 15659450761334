import React from "react";
import "./MyInput.sass";

function MyInput({
  name = "my-input",
  disabled = false,
  placeholder = "",
  required = false,
  type = "text",
  value,
  handler,
  error = "",
  classes = "",
  max = undefined,
  onBlur = () => {},
}) {
  return (
    <div className={`my-input ${classes}`}>
      <input
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        required={required}
        type={type}
        value={value}
        onChange={handler}
        onBlur={onBlur}
        max={max}
      />
      {error ? <p className="error">{error}</p> : null}
    </div>
  );
}

export default MyInput;
