import React, { useEffect, useState } from "react";
import "./Toaster.sass";
// import { ToastContainer, toast } from "react-toastify";
import event from "../../../event";
import golds from "@assets/imgs/golds.png";

function Toaster() {
  let timer;
  let [show, setShow] = useState(false);
  let [toast, setToast] = useState({
    text: "",
    subText: "",
    img: golds,
  });

  useEffect(() => {
    console.log("sbus soer");
    event.unsubscribe("toast");

    event.subscribe("toast", (data) => {
      if (show) return;
      let newToast = Object.assign({}, toast, data);
      console.log(newToast, "new");
      setToast(newToast);
      setShow(true);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShow(false);
      }, 3000);
    });
  });

  //   console.log("Displaying Toast");
  // }, []);

  console.log(show, "show");

  return (
    <div className="toaster-overlay">
      <div
        onClick={() => setShow(false)}
        // style={{ backgroundImage: `url(${back})` }}
        className={`toaster-content ${show ? "active" : ""}`}
      >
        {toast.img ? <img src={toast.img} /> : null}

        <div>
          {toast.text ? <p>{toast.text}</p> : null}
          {toast.subText ? <p>{toast.subText}</p> : null}
        </div>
      </div>
    </div>
  );
}

export default Toaster;
