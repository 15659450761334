import $api from "@http/index.js";

export default class GameService {
  static async getGame() {
    return $api.get("/game");
  }
  static async createGame() {
    return $api.post("/game");
  }
  static async finishGame() {
    return $api.delete("/game");
  }

  static async startGame() {
    return $api.post("/game/start");
  }

  static async shotInPlayer(data) {
    return $api.post("/game/shot", data);
  }

  static async protectPlayer() {
    return $api.post("/game/protect");
  }

  static async saveConfig(config) {
    return $api.post("/game/config", config);
  }
}
