import { makeObservable, observable, computed, flow, action } from "mobx";
import PlayerService from "@services/PlayerService";
import ApiError from "@exceptions/apiError";

class Players {
  players = [];

  constructor() {
    makeObservable(
      this,
      {
        players: observable,
        setPlayers: action,
        addPlayer: action,
        updatePlayer: action,
        deletePlayer: action,
        changePlayerName: action,
        changePerks: action,
      },
      { deep: true }
    );
  }

  setPlayers(players) {
    this.players = players;
  }
  addPlayer(player) {
    this.players.push(player);
  }
  updatePlayer(player) {
    this.players = this.players.map((p) => (p._id == player._id ? player : p));
  }
  deletePlayer(playerId) {
    this.players = this.players.filter((p) => p._id !== playerId);
  }
  async changePerks(playerId, perk, action) {
    try {
      await PlayerService.changePerks(playerId, perk, action);
    } catch (e) {
      ApiError(e);
    }
  }

  async changePlayerName(playerId, name) {
    try {
      await PlayerService.changePlayerName(playerId, name);
    } catch (e) {
      ApiError(e);
    }
  }

  async changeAlive(playerId) {
    try {
      await PlayerService.changeAlive(playerId);
    } catch (e) {
      ApiError(e);
    }
  }
}

export default new Players();
