import React from "react";
import './MyCheckbox.sass';

function MyCheckbox({
  checked = false,
  handler = () => {},
  id = "my-checkbox",
  value = "",
}) {
  return (
    <div className="my-checkbox">
      <input
        type="checkbox"
        checked={checked}
        onChange={handler}
        id={id}
        name="my-checkbox"
        value={value}
        className="checkbox"
      />
      <label htmlFor={id}>{value}</label>
    </div>
  );
}

export default MyCheckbox;
