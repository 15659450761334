import React, { useEffect, Fragment, useState } from "react";
import { observer } from "mobx-react-lite";
import gameState from "@store/game";
import playerState from "@store/player";
import { authenticate, subscribePlayer } from "./socket";

import NotGamePage from "@pages/NotGamePage/NotGamePage";
import WaitGameAdmin from "@pages/WaitGameAdmin/WaitGameAdmin";
import WaitPlayerGame from "@pages/WaitPlayerGame/WaitPlayerGame";
import SelectTeam from "@pages/SelectTeam/SelectTeam";
import GamePage from "@pages/GamePage/GamePage";

import Header from "@components/blocks/Header/Header";

function Router() {
  console.log("rout rerend");

  let { isAdmin, player } = playerState;
  useEffect(() => {
    console.log("use auth");
    authenticate();
    subscribePlayer(player._id);
    gameState.getGame();
  }, []);
  let { loaded, status } = gameState;

  function ShowPage() {
    if (status) {
      if (status === "wait") {
        if (isAdmin) {
          return <WaitGameAdmin />;
        } else {
          return player.inGame ? <WaitPlayerGame /> : <SelectTeam />;
        }
      } else {
        if (isAdmin) {
          return <GamePage />;
        } else {
          return player.inGame ? <GamePage /> : <NotGamePage />;
        }
      }
    } else {
      return <NotGamePage />;
    }
  }

  return (
    <div style={{ padding: "1px 5px 20px" }}>
      <Header /> {loaded ? <ShowPage /> : null}
    </div>
  );
}

export default observer(Router);
