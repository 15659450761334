import { useEffect, useState } from "react";

const useValidation = (value, validations) => {
  const [isEmpty, setEmpty] = useState("Поле не должно быть пустым");
  const [minLength, setMinLength] = useState("");
  const [max, setMax] = useState("");
  const [min, setMin] = useState("");

  useEffect(() => {
    for (let v in validations) {
      switch (v) {
        case "minLength":
          value.length < validations[v]
            ? setMinLength(`Мин. длинна: ${validations[v]} символа`)
            : setMinLength("");

          break;
        case "isEmpty":
          setEmpty(value ? "Поле не должно быть пустым" : "");
          break;
        case "min":
          +value < validations[v]
            ? setMin(value ? `Мин: ${validations[v]}` : "")
            : setMin("");
          break;
        case "max":
          +value > validations[v]
            ? setMax(value ? `Макс: ${validations[v]}` : "")
            : setMax("");
          break;
        default:
      }
    }
  }, [value]);

  return {
    isEmpty,
    minLength,
    max,
    min,
  };
};

export default useValidation;
