import React, { useEffect, useState } from "react";
import "./Statistics.sass";
import Modal from "@components/UI/Modal/Modal";
import MyButton from "@components/UI/MyButton/MyButton";
import playerState from "@store/player";
import MyInput from "@components/UI/MyInput/MyInput";

import statisticService from "@services/StatisticService";

function ChangeStat({ player, close, list, setList }) {
  let [money, setMoney] = useState(player.money);

  function changeName(e) {
    let value = e.target.value;

    if (value < 0) {
      return;
    } else {
      setMoney(value);
    }
  }

  async function changeStat() {
    try {
      let data = { id: player._id, money };
      let response = await statisticService.change(data);
      let item = response.data;

      let newList = list.map((i) => (i._id == item._id ? item : i));
      setList(newList);
      close();
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <div className="change-stat">
      <p className="change-stat-name">{player.name}</p>

      <MyInput
        name="change-stat-money"
        placeholder="Монет"
        value={money}
        type="number"
        classes="change-stat-money"
        handler={changeName}
      />
      {player.money != money ? (
        <MyButton
          handler={changeStat}
          name="change-stat-btn"
          large
          value="Сохранить"
          classes="change-stat-btn"
        />
      ) : null}
    </div>
  );
}

function Statistics() {
  let [showModal, setShowModal] = useState(false);
  let [player, setPlayer] = useState(null);
  let [loaded, setLoaded] = useState(false);
  let [list, setList] = useState([]);

  let { isAdmin } = playerState;

  useEffect(() => {
    const getData = async () => {
      try {
        let { data } = await statisticService.get();
        setList(data);
        setLoaded(true);
      } catch (e) {
        console.error(e);
      }
    };
    getData();
  }, []);

  console.log(list, "list");

  function changeModal(player) {
    if (isAdmin) {
      setPlayer(player);
      setShowModal(true);
    }
  }

  function Table({ list }) {
    let sortList = list.sort((a, b) => b.money - a.money);
    return (
      <table className="table-list">
        <thead className="table-list-head">
          <tr>
            <th>Имя</th>
            <th>Монет</th>
            <th>Убйиств</th>
            <th>Смертей</th>
            <th>Игр</th>
          </tr>
        </thead>
        <tbody className="table-list-body">
          {sortList.map((i, c) => {
            let classes = "table-list-body-tr";
            if (c == 0) {
              classes = classes + " first";
            } else if (c == 1) {
              classes = classes + " second";
            }

            return (
              <tr onClick={() => changeModal(i)} className={classes}>
                <td>{i.name}</td>
                <td>{i.money}</td>
                <td>{i.killedCount}</td>
                <td>{i.deathCount}</td>
                <td>{i.gameCount}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  function closeModal() {
    setShowModal(false);
    setPlayer(null);
  }

  return (
    <div>
      {loaded && list.length !== 0 ? (
        <Table list={list} />
      ) : (
        <p className="stat-header">Статистика пуста!</p>
      )}

      <Modal
        active={showModal}
        setActive={() => {
          setShowModal(false);
          setPlayer(null);
        }}
      >
        {player ? (
          <ChangeStat
            close={closeModal}
            list={list}
            setList={setList}
            player={player}
          />
        ) : null}
      </Modal>
    </div>
  );
}

export default Statistics;
