import React from "react";

import revolverImg from "@assets/imgs/revolver.png";
import shieldImg from "@assets/imgs/shield.png";

import playerState from "@store/player";
import playersState from "@store/players";
import gameState from "@store/game";

import Timer from "./Timer";

function Controls({ duel }) {
  let initSecond = Math.floor((new Date(duel.finished) - Date.now()) / 1000);

  function showProtect() {
    let currentPlayer = playerState.player;

    currentPlayer = playersState.players.find(
      (p) => p._id == currentPlayer._id
    );

    if (!currentPlayer) return false;

    let victimPlayer = playersState.players.find((p) => p._id == duel.player2);

    let ourTeam =
      victimPlayer.team == currentPlayer.team ||
      victimPlayer._id === currentPlayer._id;
    return ourTeam && currentPlayer.protectAvailable;
  }

  function protectPlayer() {
    gameState.protectPlayer();
  }

  return (
    <div className="duel-content_controllers">
      <div className="duel-content_controllers-time">
        <Timer duelResult={duel.duelResult} initSecond={initSecond} />
      </div>
      <div className="duel-content_controllers-revolver">
        <img src={revolverImg} />
      </div>
      {showProtect() ? (
        <div onClick={protectPlayer} className="duel-content_controllers-guard">
          <img src={shieldImg} />
        </div>
      ) : null}
    </div>
  );
}

export default Controls;
