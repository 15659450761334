import React, { useState } from "react";
import "./Authorization.sass";
import useInput from "@hooks/useInput.js";
import MyInput from "@components/UI/MyInput/MyInput";
import MyButton from "@components/UI/MyButton/MyButton";
import MyCheckbox from "@components/UI/MyCheckbox/MyCheckbox";
import playerState from "@store/player";

function Authorization() {
  let email = useInput("", { minLength: 5 });
  let password = useInput("", { minLength: 3 });
  let [rememberMe, setRememberMe] = useState(false);
  let [error, setError] = useState("");

  function onSubmit(e) {
    e.preventDefault();
    playerState.login(email.value, password.value, rememberMe, setError);
  }

  function submitDisabled() {
    let disabled =
      !email.value || email.minLength || !password.value || password.minLength;
    return !!disabled;
  }

  return (
    <section className="auth-page">
      <p className="version">Version 1.0.1</p>

      <div className="wrap">
        <h3>Авторизация</h3>
        {error ? <p>{error}</p> : null}

        <form className="auth-form">
          <MyInput
            name="email"
            type="email"
            placeholder="Email"
            classes="auth-input"
            required={true}
            value={email.value}
            handler={email.onChange}
            onBlur={email.onBlur}
            error={email.isDirty && email.minLength}
          />

          <MyInput
            name="password"
            placeholder="Пароль"
            required={true}
            classes="auth-input"
            value={password.value}
            handler={password.onChange}
            onBlur={password.onBlur}
            error={password.isDirty && password.minLength}
          />
          <MyCheckbox
            checked={rememberMe}
            handler={() => setRememberMe(!rememberMe)}
            value="Запомнить меня?"
          />
          <MyButton
            name="reg"
            type="submit"
            classes="auth-submit"
            value="Войти"
            disabled={submitDisabled()}
            handler={onSubmit}
          />
        </form>
      </div>
    </section>
  );
}

export default Authorization;
