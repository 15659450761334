import React from "react";
import "./TeamPerks.sass";
import { observer } from "mobx-react-lite";

import playersState from "@store/players";
import playerState from "@store/player";

import goldStar from "@assets/imgs/gold-star.png";
import greyStar from "@assets/imgs/grey-star.png";

import whiteCross from "@assets/imgs/white-cross.png";
import greyCross from "@assets/imgs/grey-cross.png";

function TeamPerks({ classes = "" }) {
  let { players } = playersState;
  let { player } = playerState;

  let currentPlayer = players.find((p) => p._id == player._id);
  if (!currentPlayer) return null;
  let teamId = currentPlayer.team;

  function getStars() {
    let stars = 0;
    players.forEach((p) => {
      if (p.team === teamId) {
        stars += p.stars;
      }
    });
    let result = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= stars) {
        result.push(goldStar);
      } else {
        result.push(greyStar);
      }
    }
    return result;
  }

  function getCrosses() {
    let crosses = 0;
    players.forEach((p) => {
      if (p.team === teamId) {
        crosses += p.crosses;
      }
    });
    let result = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= crosses) {
        result.push(whiteCross);
      } else {
        result.push(greyCross);
      }
    }
    return result;
  }

  return (
    <div className={`team-perks-list`}>
      <div className="team-perks-stars">
        {getStars().map((i, p) => (
          <img key={p} src={i} className="star" alt="Звезда" />
        ))}
      </div>
      <div className="team-perks-crosses">
        {getCrosses().map((i, p) => (
          <img key={p} src={i} className="star" alt="Крест" />
        ))}
      </div>
    </div>
  );
}

export default observer(TeamPerks);
