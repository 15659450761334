import React, { useState } from "react";
import './Bots.sass';
import MySelect from "@components/UI/MySelect/MySelect";

import rateShot from "./rateShot.json";
import chanceShot from "./chanceShot.json";
import gameState from "@store/game";

function Bots() {
  let { config } = gameState;

  let [freqBot, setFreqBot] = useState(config.freqBot);
  let [chanceBot, setChanceBot] = useState(config.chanceBot);

  function freqBotHandler(value) {
    gameState.setGameConfig("freq", value);
    setFreqBot(value);
  }

  function chanceBotHandler(value) {
    gameState.setGameConfig("chance", value);
    setChanceBot(value);
  }

  return (
    <div className="tab-body">
      <p>Частота стрельбы бота</p>
      <MySelect
        classes="tab-select"
        list={rateShot}
        select={freqBot}
        setSelect={freqBotHandler}
      />
      <p>Вероятность попадания бота</p>
      <MySelect
        classes="tab-select"
        list={chanceShot}
        select={chanceBot}
        setSelect={chanceBotHandler}
      />
    </div>
  );
}

export default Bots;
