import React, { useState, useEffect } from "react";
import Modal from "@components/UI/Modal/Modal";
// import event from "../../../event";
import { observer } from "mobx-react-lite";
import ReactHowler from "react-howler";
import duelSample from "@assets/samples/duel.mp3";

import Content from "./Content/Content";

import gameState from "@store/game";

function Duel() {
  let { showDuel } = gameState;

  function closeHandler() {
    gameState.hideDuel();
  }

  return (
    <div className="duel-overlay">
      {showDuel ? (
        <div>
          <ReactHowler src={duelSample} playing={true} />
          <Modal active setActive={closeHandler}>
            <Content />
          </Modal>
        </div>
      ) : null}
    </div>
  );
}

export default observer(Duel);
