import React, { useState } from "react";
import "./MySelect.sass";

function MySelect({ list, select, setSelect, classes = "" }) {
  const [isOpen, setOpen] = useState(false);

  if ((!select && list.length !== 0)) {
    setSelect(list[0].value);
  }

  const toggleDropdown = () => setOpen(!isOpen);

  const handleItemClick = (value) => {
    setSelect(value);
    toggleDropdown();
  };

  return (
    <div className={`dropdown ${classes}`}>
      <div className="dropdown-header" onClick={toggleDropdown}>
        {select
          ? list.find((item) => item.value == select).label
          : "Select your destination"}
      </div>

      <div className={`dropdown-body ${isOpen && "open"}`}>
        {list.map((item) => (
          <div
            className="dropdown-item"
            onClick={(e) => handleItemClick(e.target.id)}
            id={item.value}
            key={item.value}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MySelect;
