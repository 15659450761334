import React, { useEffect, useState, Fragment } from "react";
import "./Content.sass";
import { observer } from "mobx-react-lite";
import playersState from "@store/players";

import gameState from "@store/game";

import Result from "./Result";
import Process from "./Process/Process";

function Content({}) {
  let { duel } = gameState;
  let { players } = playersState;

  let player1 = players.find((p) => p._id == duel.player1);
  let player2 = players.find((p) => p._id == duel.player2);

  let finish = duel.status === "finish";

  return (
    <div className="duel-content">
      {!finish ? (
        <Process
          setProcess={() => {}}
          duel={duel}
          player1={player1}
          player2={player2}
        />
      ) : (
        <Result
          player={player2}
          duelResult={duel.duelResult}
          prize={duel.prize}
        />
      )}
    </div>
  );
}

export default observer(Content);
