import React, { useState } from "react";
import "./GamePage.sass";

import Target from "./Target/Target";

import TeamList from "./TeamList/TeamList";
import VictimСhoice from "./VictimСhoice/VictimСhoice";
import Duel from "./Duel/Duel";
import Finish from "./Finish/Finish";
import TeamPerks from "./TeamPerks/TeamPerks";

import StarPrice from "@components/blocks/StarPrice/StarPrice";

function GamePage() {
  console.log("game page render");
  let [choice, setChoice] = useState(null);

  return (
    <div className="game-page">
      <StarPrice />
      <TeamPerks />
      <TeamList alive />
      <Target handler={() => setChoice(true)} />

      <TeamList alive={false} />

      <VictimСhoice close={() => setChoice(false)} show={choice} />
      <Duel />
      <Finish />
    </div>
  );
}

export default GamePage;
