import React from "react";
import "./Players.sass";
import { observer } from "mobx-react-lite";
import playerState from "@store/players";

import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";

function Players({ teamId }) {
  let { players } = playerState;

  players = players.filter((p) => p.team == teamId);

  return (
    <div className="player-list">
      {players.map((p) => (
        <div key={p._id} className="player-item">
          <PlayerCard player={p} imgSize="g" showControllers />
        </div>
      ))}
    </div>
  );
}

export default observer(Players);
