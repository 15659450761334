import React, { useState } from "react";
import "./Game.sass";
import timeGame from "./timeGame.json";
import MySelect from "@components/UI/MySelect/MySelect";
import MyInput from "@components/UI/MyInput/MyInput";

import useInput from "@hooks/useInput.js";
import gameState from "@store/game";

function Game() {
  let { config } = gameState;

  let [gameTime, setGameTime] = useState(config.gameTime);
  let maxPlayers = useInput(config.maxPlayers, {
    min: 6,
    max: 36,
  });

  function gameTimeHandler(value) {
    gameState.setGameConfig("time", value);
    setGameTime(value);
  }

  function maxPlayersHandler(e) {
    gameState.setGameConfig("max", e.target.value);
    maxPlayers.onChange(e);
  }

  return (
    <div className="tab-body">
      <p>Время партии</p>
      <MySelect
        classes="tab-select"
        list={timeGame}
        select={gameTime}
        setSelect={gameTimeHandler}
      />
      <p>Макс. количество игроков</p>
      <MyInput
        type="number"
        name="players"
        placeholder="Макс игроков"
        required={true}
        value={maxPlayers.value}
        handler={maxPlayersHandler}
        onBlur={maxPlayers.onBlur}
        error={maxPlayers.isDirty && (maxPlayers.min || maxPlayers.max)}
      />
    </div>
  );
}

export default Game;
