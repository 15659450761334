import React, { useState } from "react";
import "./TeamCard.sass";
import { observer } from "mobx-react-lite";
import Modal from "@components/UI/Modal/Modal";

import playersState from "@store/players";
import playerState from "@store/player";

import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";
// import TeamPerks from "./TeamPerks/TeamPerks";

import bonesImg from "@assets/imgs/bones.png";

import ChangePlayer from "./ChangePlayer/ChangePlayer";

function TeamCard({ team, alive }) {
  let [openPlayer, setOpenPlayer] = useState(false);
  let [openPlyerId, setOpenPlayerId] = useState(null);
  let { players } = playersState;
  let { isAdmin, player } = playerState;

  let currentPlayer = players.find((p) => p._id == player._id);
  let isShowPerks =
    (currentPlayer && currentPlayer.team == team._id) || isAdmin;

  let selectOpenPlayer = players.find((p) => p._id === openPlyerId);

  players = players.filter((p) => p.alive === alive && p.team === team._id);

  // if (players.length == 0) return null;

  function showModalPlayer(playerId) {
    if (isAdmin) {
      setOpenPlayerId(playerId);
      setOpenPlayer(true);
    }
  }

  function hideModalPlayer() {
    setOpenPlayer(false);
    setOpenPlayerId(null);
  }

  let style = alive ? {} : { backgroundImage: `url(${bonesImg})` };
  let aliveClasses = alive
    ? "game-page_team-card"
    : "game-page_team-card deads-players";

  return (
    <div style={style} className={aliveClasses}>
      {alive ? <h3 className="game-page_team-card_name">{team.name}</h3> : null}

      <div className="game-page_team-card_player-list">
        {players.map((p) => (
          <div key={p._id} className="game-page_team-card_player-item">
            <PlayerCard
              showPerks={isShowPerks}
              onClick={() => showModalPlayer(p._id)}
              player={p}
              showName
              imgSize="g"
              showKush
            />
          </div>
        ))}
      </div>

      <Modal active={openPlayer} setActive={hideModalPlayer}>
        {selectOpenPlayer ? (
          <ChangePlayer close={hideModalPlayer} player={selectOpenPlayer} />
        ) : null}
      </Modal>
    </div>
  );
}

export default observer(TeamCard);
