import React, { useEffect } from "react";
import "./TeamList.sass";
import { observer } from "mobx-react-lite";
import { API_URL } from "@http";

import teamsState from "@store/teams";

function TeamList({ classes = "", selectedTeam, setSelectedTeam }) {
  const { getFreeTeams } = teamsState;

  if (getFreeTeams.length !== 0) {
    if (!selectedTeam) {
      setSelectedTeam(getFreeTeams[0]._id);
    } else if (
      selectedTeam &&
      !getFreeTeams.find((t) => t._id === selectedTeam)
    ) {
      setSelectedTeam(getFreeTeams[0]._id);
    }
  }

  function TeamCard({ team }) {
    return (
      <div onClick={() => setSelectedTeam(team._id)} className={`team-card`}>
        <div className="img-wrap">
          <img
            width="100%"
            alt={team.name}
            src={`${API_URL}/images/${team.img}.jpg`}
          />
        </div>
        <h5 className={`name ${selectedTeam === team._id ? "select" : null}`}>
          {team.name}
        </h5>
      </div>
    );
  }

  return (
    <div className={`team-list ${classes}`}>
      {getFreeTeams.map((t) => {
        return <TeamCard key={t._id} team={t} />;
      })}
    </div>
  );
}

export default observer(TeamList);
