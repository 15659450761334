import axios from "axios";

let PORT = 8000;

let API_URL = `http://localhost:${PORT}/api`;
let SOCKET_URL = `http://localhost:${PORT}`;

if (process.env.NODE_ENV === "production") {
  API_URL = "https://api.doa.progms.pro/api";
  SOCKET_URL = "https://api.doa.progms.pro";
}

export { API_URL, SOCKET_URL };

const $api = axios.create({
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = "Bearer " + localStorage.getItem("token");
  return config;
});

export default $api;
