
export default function ApiError(e) {
  // console.log(e.response.data);
  
    console.error(e, 'e')
  // const status = e.response.status;


  // if (status === 401 || status === 403) {
  //   // playerState.logout();
  // }
}
