import React, { useEffect } from "react";
import "./AvatarList.sass";
import { observer } from "mobx-react-lite";
import { API_URL } from "@http";
import dataState from "@store/data";

function AvatarList({ classes = "", avatar, setAvatar }) {
  useEffect(() => {
    dataState.fetchData();
  }, []);

  const { data } = dataState;

  if (data.length !== 0 && !avatar) {
    setAvatar(data[0]);
  }

  return (
    <div className={`avatar-list ${classes}`}>
      {data.map((a) => {
        return (
          <div key={a} className="avatar">
            <img
              className={`${avatar === a ? "select" : ""}`}
              onClick={() => setAvatar(a)}
              alt="Автар"
              width="100%"
              src={`${API_URL}/images/${a}-g.png`}
            />
          </div>
        );
      })}
    </div>
  );
}

export default observer(AvatarList);
