import React from "react";
import "./TeamList.sass";

import { observer } from "mobx-react-lite";
import teamsState from "@store/teams";
import TeamCard from "./TeamCard/TeamCard";

function TeamList({ alive }) {
  let { teams } = teamsState;

  return (
    <div className="game-page_team-list">
      {teams.map((t) => (
        <TeamCard key={t._id} alive={alive} team={t} />
      ))}
    </div>
  );
}

export default observer(TeamList);
