import React from "react";
import "./Modal.sass";

function Modal({ active = true, setActive, children }) {
  return (
    <div
      className={`modal ${active ? "active" : ""}`}
      onClick={() => setActive(false)}
    >
      <div className="modal_content" onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
