import React, { useEffect } from "react";
import ReactHowler from "react-howler";

import sample from "@assets/samples/tick.mp3";
import fire from "@assets/samples/fire.mp3";
import mimo from "@assets/samples/mimo.mp3";

function Timer({ initSecond, duelResult }) {
  const [seconds, setSeconds] = React.useState(initSecond);

  useEffect(() => {
    let timerId;
    if (seconds > 0) {
      timerId = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds("0");
    }
    return () => {
      clearTimeout(timerId);
    };
  });

  let resultMusic;

  if (duelResult === "kill" || duelResult === "rob") {
    resultMusic = fire;
  } else if (duelResult === "miss" || duelResult === "failRob") {
    resultMusic = mimo;
  }

  let isTicker = seconds !== "0";

  return (
    <div>
      {isTicker ? (
        <ReactHowler src={sample} playing={true} />
      ) : (
        <ReactHowler src={resultMusic} playing={true} />
      )}

      <div>{seconds}</div>
    </div>
  );
}

export default Timer;
