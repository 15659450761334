import React from "react";
import "./Teams.sass";
import Team from "./Team/Team";

import { observer } from "mobx-react-lite";

import teamsState from "@store/teams";

function Teams() {
  let { teams } = teamsState;

  console.log(teams, "teams");
  return (
    <div className="wait-game-team-list">
      {teams.map((t) => (
        <Team key={t._id} team={t} />
      ))}
    </div>
  );
}

export default observer(Teams);
