import { useState, useEffect } from "react";
import useValidation from "./useValidation";



export default function useInput(initialValue, validations) {
  let [value, setValue] = useState(initialValue);
  let [isDirty, setDirty] = useState(false);
  const valid = useValidation(value, validations);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = (e) => {
    setDirty(true);
  };

  return {
    value,
    onChange,
    onBlur,
    isDirty,
    ...valid,
    setValue
  };
}
