import React from "react";
import "./Header.sass";
import { observer } from "mobx-react-lite";
import MyButton from "@components/UI/MyButton/MyButton";
import playerState from "@store/player";
import gameState from "@store/game";

function Header() {
  let { player, isAdmin } = playerState;
  let { game } = gameState;

  let email = player.email;

  return (
    <div className="main-header">
      <div className="header-flex-grid">
        {isAdmin && game ? (
          <MyButton
            name="finish-game"
            type="button"
            classes="finish-game"
            value="Завершить игру!"
            handler={() => gameState.finishGame()}
          />
        ) : null}
      </div>
      <div className="header-flex-grid user-email">{email}</div>
      <div className="header-flex-grid">
        <MyButton
          name="logout"
          type="button"
          classes="logout-button"
          value="Выйти"
          handler={() => playerState.logout()}
        />
      </div>
    </div>
  );
}

export default observer(Header);
