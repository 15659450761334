import React, { useState } from "react";
import "./MyButton.sass";

import bi1 from "@assets/imgs/lb1.png";
import bi2 from "@assets/imgs/mb1.png";

function MyButton({
  disabled = false,
  name = "my-button",
  type = "button",
  value = "Кнопка",
  handler,
  classes = "",
  large = false,
}) {
  return (
    <button
      style={{
        backgroundImage: `url(${large ? bi1 : bi2})`,
      }}
      className={`my-button ${large ? "large" : ""} ${classes} `}
      name={name}
      type={type}
      disabled={disabled}
      onClick={handler}
    >
      {value}
    </button>
  );
}

export default MyButton;
