import { makeObservable, observable, computed, action } from "mobx";
import PlayerService from "@services/PlayerService";
import ApiError from "../exceptions/apiError";
import { authenticate } from "../socket";

class Player {
  player = null;
  isAuthenticated = false;
  loaded = false;

  constructor() {
    makeObservable(
      this,
      {
        player: observable,
        isAuthenticated: observable,
        loaded: observable,
        isAdmin: computed,
        login: action,
        getPlayer: action,
        setPlayer: action,
        setAuth: action,
        setLoaded: action,
        logout: action,
      },
      { deep: true }
    );
  }

  get isAdmin() {
    return this.player && this.player.role === "admin";
  }

  setPlayer(player) {
    this.player = player;
  }

  setAuth(auth) {
    this.isAuthenticated = auth;
  }
  setLoaded(loaded) {
    this.loaded = loaded;
  }
  logout() {
    this.setAuth(false);
    this.setPlayer(null);
    localStorage.removeItem("token");
  }

  async login(email, password, rememberMe, setError) {
    try {
      const response = await PlayerService.login(email, password, rememberMe);
      localStorage.setItem("token", response.data.token);

      this.setPlayer(response.data.player);
      this.setAuth(true);
      this.setLoaded(true);
      authenticate();
    } catch (e) {
      console.log(e, "error");

      setError(e.response.data.message);
      ApiError(e);
    }
  }

  async getPlayer() {
    try {
      let token = localStorage.getItem("token");
      if (!token) return;

      const response = await PlayerService.getProfile();

      this.setPlayer(response.data);
      this.setAuth(true);
      authenticate();
    } catch (e) {
      ApiError(e);
    } finally {
      this.setLoaded(true);
    }
  }
}

export default new Player();
