import React from "react";
import "./Perks.sass";

import goldStar from "@assets/imgs/gold-star.png";
import crossImg from "@assets/imgs/white-cross.png";

function Perks({ player }) {
  let stars = ["", "", ""]
    .map((s, i) => (i + 1 > player.stars ? false : goldStar))
    .filter((s) => s);
  let crosses = ["", "", ""]
    .map((c, i) => (i + 1 > player.crosses ? false : crossImg))
    .filter((s) => s);

  return (
    <div>
      {player.alive ? (
        <div className="stars">
          {stars.map((s) => (
            <img src={s} className="star" />
          ))}
        </div>
      ) : (
        <div className="stars">
          {crosses.map((s) => (
            <img src={s} className="star" />
          ))}
        </div>
      )}
    </div>
  );
}

export default Perks;
