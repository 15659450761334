import React, { useState } from "react";
import "./Team.sass";
import { observer } from "mobx-react-lite";
import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";

import playerState from "@store/players";

function Team({ team }) {
  let { players } = playerState;

  players = players.filter((p) => p.team == team._id);

  return (
    <div className="wait-player-game_team-card">
      <h3 className="wait-player-game_team-name">{team.name} </h3>

      <div className="wait-player-game_player-list">
        {players.map((p) => (
          <div key={p._id} className="wait-player-game_player-item">
            <PlayerCard  showName player={p} imgSize="g" />
          </div>
        ))}
      </div>

      {/* <Players teamId={team._id} /> */}
    </div>
  );
}
export default observer(Team);
