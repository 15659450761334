import React from "react";
import "./NotGamePage.sass";
import MyButton from "@components/UI/MyButton/MyButton";
import gameState from "@store/game";
import playerState from "@store/player";

import Statistics from "./Statistics/Statistics";

function NotGamePage() {
  const isAdmin = playerState.isAdmin;

  function AdminView() {
    return (
      <div>
        <div className="offer">
          <p>Нет активной игры. Хотите её создать?</p>
          <MyButton
            handler={gameState.createGame}
            name="create-game"
            large
            value="Создать"
            classes="create-button"
          />
        </div>
        <Statistics />
      </div>
    );
  }

  function View() {
    return isAdmin ? <AdminView /> : <Statistics />;
  }

  return (
    <div className="not-game">
      <View />
    </div>
  );
}

export default NotGamePage;
