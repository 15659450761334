import React, { useEffect } from "react";

import "./App.sass";
import Router from "./Router";
import Authorization from "@pages/Authorization/Authorization";
import { observer } from "mobx-react-lite";
import backImg from "@assets/imgs/b4.jpg";
import playerState from "@store/player";
import Toaster from "@components/UI/Toaster/Toaster";

function App() {
  console.log(playerState.player, "playerState");
  useEffect(() => {
    playerState.getPlayer();
  }, []);

  let { isAuthenticated, loaded } = playerState;

  const body = isAuthenticated ? <Router /> : <Authorization />;

  return (
    <div style={{ backgroundImage: `url(${backImg})` }} className="App">
      <Toaster />
      {loaded ? body : null}
    </div>
  );
}

export default observer(App);
