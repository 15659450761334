import $api from "@http/index.js";

export default class PlayerService {
  static async changePlayerName(playerId, name) {
    return $api.post("/player/name", { playerId, name });
  }

  static async changePerks(playerId, perk, action) {
    return $api.post("/player/perk", { playerId, perk, action });
  }

  static async buyStar() {
    return $api.post("/player/buy");
  }

  static async changeAlive(playerId) {
    return $api.post("/player/alive", { playerId });
  }

  static async login(email, password) {
    return $api.post("/login", { email, password });
  }

  static async getProfile() {
    return $api.get("/profile");
  }
}
