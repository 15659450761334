import React, { useState } from "react";
import "./Team.sass";

import teamState from "@store/teams";

import MyInput from "@components/UI/MyInput/MyInput";
import MyButton from "@components/UI/MyButton/MyButton";

import Players from "./Players/Players";

function Team({ team }) {
  let [name, setName] = useState(team.name);
  return (
    <div className="team-card">
      <div className="change-name-section">
        <MyInput
          type="text"
          name="team-name-input"
          placeholder="Название"
          required={true}
          value={name}
          classes="team-name-input"
          handler={(e) => setName(e.target.value)}
        />

        {team.name !== name && name ? (
          <MyButton
            name="save-name-team"
            type="button"
            classes="save-name-team"
            value="Сохранить"
            // disabled={submitDisabled()}
            handler={() => teamState.changeTeamName(team._id, name)}
          />
        ) : null}
      </div>
      <Players teamId={team._id} />

      {team.free ? (
        <MyButton
          name="add-bot-button"
          type="button"
          classes="add-bot-button"
          value="Добавить бота"
          handler={() => teamState.addBot(team._id)}
        />
      ) : null}
    </div>
  );
}
export default Team;
