import { makeObservable, observable, computed, action } from "mobx";
import TeamService from "@services/TeamService";
import ApiError from "../exceptions/apiError";

class Teams {
  teams = [];

  constructor() {
    makeObservable(
      this,
      {
        teams: observable,
        changeTeamName: action,
        setTeams: action,
        updateTeam: action,

        getFreeTeams: computed,
        joinTheTeam: action,
        //
        kickPlayer: action,
        addBot: action,
      },
      { deep: true }
    );
  }

  setTeams(teams) {
    this.teams = teams;
  }

  updateTeam(team) {
    this.teams = this.teams.map((t) => (t._id === team._id ? team : t));
  }

  async changeTeamName(teamId, name) {
    try {
      await TeamService.changeTeamName(teamId, name);
    } catch (e) {
      ApiError(e);
    }
  }

  async addBot(teamId) {
    try {
      await TeamService.addBot(teamId);
    } catch (e) {
      ApiError(e);
    }
  }

  get getFreeTeams() {
    return this.teams.filter((t) => t.free);
  }

  async kickPlayer(playerId) {
    try {
      await TeamService.kickPlayer(playerId);
    } catch (e) {
      ApiError(e);
    }
  }

  async joinTheTeam(teamId, name, avatar) {
    try {
      await TeamService.joinTheTeam(teamId, name, avatar);
    } catch (e) {
      ApiError(e);
    }
  }
}

export default new Teams();
