import React from "react";
import "./WaitPlayerGame.sass";

import { observer } from "mobx-react-lite";
import teamsState from "@store/teams";

import Team from "./Team/Team";

function WaitPlayerGame() {
  let { teams } = teamsState;

  return (
    <div className="wait-player-game">
      <h1 className="wait-player-game_header"> Ожидание игры</h1>

      <div className="wait-player-game_team-list">
        {teams.map((t) => (
          <Team key={t._id} team={t} />
        ))}
      </div>
    </div>
  );
}

export default observer(WaitPlayerGame);
