import React from "react";
import "./Target.sass";
import { observer } from "mobx-react-lite";

import playerState from "@store/player";
import playersState from "@store/players";
import targetImg from "@assets/imgs/target.png";

function Target({ handler }) {
  let { player } = playerState;
  let { players } = playersState;

  let currentPlayer = players.find((p) => p._id == player._id);
  let shotAvailable = currentPlayer && currentPlayer.shotAvailable;

  

  function clickShot() {
    if (shotAvailable) {
      console.log("shot");
      handler();
    }

    // console.log("click");
  }

  return (
    <div>
      <img
        onClick={clickShot}
        src={targetImg}
        className={`game-page_target ${shotAvailable ? "show" : ""}`}
        alt="Выстрелить"
      />
    </div>
  );
}

export default observer(Target);
