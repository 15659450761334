import io from "socket.io-client";
import { API_URL, SOCKET_URL } from "@http/index";
import gameState from "@store/game";
import playersState from "@store/players";
import playerState from "@store/player";
import teamsState from "@store/teams";

const socket = io(SOCKET_URL, {
  transports: ["polling", "websocket", "flashsocket"],
  reconnection: true,
  withCredentials: true
});

socket.on("connect", () => {
  console.log("Socket connect!");
  authenticate();
});

function authenticate() {
  const token = localStorage.getItem("token");

  if (token) {
    // console.log("authenticate");
    socket.emit("authenticate", token);
  }
}

socket.on("disconnect", () => {
  console.log("disconnect");
  // socket.connect();
});

function subscribePlayer(playerId) {
  socket.on(`update_profile_${playerId}`, (player) => {
    playerState.setPlayer(player);
  });
}

socket.on(`update_game`, (data) => {
  gameState.setGame(data.game);
  teamsState.setTeams(data.teams);
  playersState.setPlayers(data.players);
  console.log(data.game, "update game");
});

socket.on("update_team", (team) => {
  teamsState.updateTeam(team);
  // console.log(team, "update team");
});

socket.on("add_player", async (player) => {
  playersState.addPlayer(player);
  console.log(player, "addPlayer");
});

socket.on("update_player", async (player) => {
  playersState.updatePlayer(player);
  console.log(player, "updatePlayer");
});

socket.on("delete_player", async (playerId) => {
  playersState.deletePlayer(playerId);
  console.log(playerId, "deletePlayer");
});

export { authenticate, subscribePlayer };
