import React, { useState } from "react";
import "./SelectTeam.sass";
import { observer } from "mobx-react-lite";
import MyInput from "@components/UI/MyInput/MyInput";
import MyButton from "@components/UI/MyButton/MyButton";
import useInput from "@hooks/useInput.js";

import AvatarList from "./AvatarList/AvatarList";
import TeamList from "./TeamList/TeamList";

import teamsState from "@store/teams";

function SelectTeam() {
  let name = useInput("", { minLength: 3 });
  let [selectedTeam, setSelectedTeam] = useState("");
  let [avatar, setAvatar] = useState("");

  function startGame() {
    teamsState.joinTheTeam(selectedTeam, name.value, avatar);
  }

  function disabledButton() {
    return name.minLength || !name.value;
  }

  return (
    <div className="select-team-page">
      <TeamList selectedTeam={selectedTeam} setSelectedTeam={setSelectedTeam} />
      <AvatarList avatar={avatar} setAvatar={setAvatar} />

      <div className="select-team_info">
        <p className="name">Имя</p>
        <MyInput
          name="name"
          placeholder="Имя"
          required={true}
          value={name.value}
          handler={name.onChange}
          onBlur={name.onBlur}
          error={name.isDirty && name.minLength}
        />

        <MyButton
          large
          name="join-the-game"
          value="Присоединиться к игре!"
          type="button"
          classes="start-game"
          handler={startGame}
          disabled={disabledButton()}
        />
      </div>
    </div>
  );
}

export default SelectTeam;
