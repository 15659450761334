import $api from "@http/index.js";

export default class TeamService {
  static async addBot(teamId) {
    return $api.post("/team/add", { teamId });
  }

  static async changeTeamName(teamId, name) {
    return $api.post("/team/name", { teamId, name });
  }

  static async kickPlayer(playerId) {
    return $api.post("/team/kick", { playerId });
  }

  static async joinTheTeam(teamId, name, avatar) {
    return $api.post("/team/join", { teamId, name, avatar });
  }
}
