import React from "react";
import { observer } from "mobx-react-lite";
import "./StarPrice.sass";

import playerService from "@services/PlayerService";

import gameState from "@store/game";
import playerState from "@store/player";
import playersState from "@store/players";
import goldStar from "@assets/imgs/gold-star.png";

function StarPrice() {
  let { game } = gameState;
  let { starPrice } = game;
  let currentProfile = playerState.player;
  let { players } = playersState;
  let currentPlayer = players.find((p) => p._id == currentProfile._id);
  let availableBuy = currentPlayer && currentPlayer.kush >= starPrice;

  function buyHandler() {
    if (availableBuy) {
        console.log('buy handler')
      playerService.buyStar();

    }
  }

  return (
    <div className="star-price-section" onClick={buyHandler}>
      <img src={goldStar} className="star-price-section_image" />
      <p
        className={`star-price-section_text ${availableBuy ? "available" : ""}`}
      >
        {starPrice}$
      </p>
    </div>
  );
}

export default observer(StarPrice);
