import React, { useState } from "react";
import "./ControlBar.sass";

import playersState from "@store/players";
import teamsState from "@store/teams";

import cross from "@assets/imgs/cross.svg";

import MyInput from "@components/UI/MyInput/MyInput";
import MyButton from "@components/UI/MyButton/MyButton";

function ControlBar({ player }) {
  let [name, setName] = useState(player.name);
  function changePlayerName() {
    playersState.changePlayerName(player._id, name);
  }

  function kickPlayer() {
    teamsState.kickPlayer(player._id);
  }

  return (
    <div className="player-card-contol">
      <div className="btns">
        <MyInput
          name="change-user-name"
          placeholder="Имя игрока"
          value={name}
          handler={(e) => setName(e.target.value)}
        />
        <img
          onClick={kickPlayer}
          className="kick-user"
          alt="Кикнуть"
          src={cross}
        />
      </div>
      {name && name !== player.name ? (
        <MyButton
          handler={changePlayerName}
          disabled={name.length === 0 || player.name === name}
          name="save-user-name"
          value="Сохранить"
          classes="save-user-name"
        />
      ) : null}
    </div>
  );
}

export default ControlBar;
