import React, { useState } from "react";
import "./ChangePlayer.sass";
import { API_URL } from "@http";
import MyInput from "@components/UI/MyInput/MyInput";
import MyButton from "@components/UI/MyButton/MyButton";
import playersState from "@store/players";
import teamsState from "@store/teams";
import bp from "@assets/imgs/bac.jpg";
import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";
import goldStar from "@assets/imgs/gold-star.png";
import crossImg from "@assets/imgs/white-cross.png";

function ChangePlayer({ player, close = () => {} }) {
  let [name, setName] = useState(player.name);

  function changePlayerName() {
    playersState.changePlayerName(player._id, name);
  }

  function kickPlayer() {
    close();
    teamsState.kickPlayer(player._id);
  }

  function changeAlive() {
    close();
    playersState.changeAlive(player._id);
  }

  function changePerks(perk, action) {
    playersState.changePerks(player._id, perk, action);
  }

  return (
    <div style={{ backgroundImage: `url(${bp})` }} className="change-player">
      <MyButton
        handler={kickPlayer}
        name="kick-player"
        value="Кикнуть"
        classes="kick-player"
      />
      <PlayerCard player={player} imgSize="b" classes="card" hideName />
      <MyInput
        name="change-player-name"
        placeholder="Имя игрока"
        value={name}
        classes="change-player-name"
        handler={(e) => setName(e.target.value)}
      />
      {name.length === 0 || player.name === name ? null : (
        <MyButton
          handler={changePlayerName}
          name="save-player-name"
          value="Сохранить"
          classes="save-player-name"
        />
      )}

      <div className="change-perks">
        <p onClick={() => changePerks("star", "sub")}>-</p>
        <img src={goldStar} className="change-perks-img" alt="Звезда" />
        <p onClick={() => changePerks("star", "add")}>+</p>
      </div>

      <div className="change-perks">
        <p onClick={() => changePerks("cross", "sub")}>-</p>
        <img src={crossImg} className="change-perks-img" alt="Крест" />
        <p onClick={() => changePerks("cross", "add")}>+</p>
      </div>
      <MyButton
        handler={changeAlive}
        name="save-player-name"
        value={player.alive ? "Убить" : "Воскресить"}
        classes="save-player-name"
      />
    </div>
  );
}

export default ChangePlayer;
