import React from "react";
import "./VictimChoice.sass";
import Modal from "@components/UI/Modal/Modal";
import back from "@assets/imgs/bb.jpg";

import playersState from "@store/players";
import playerState from "@store/player";
import teamsState from "@store/teams";
import targetImg from "@assets/imgs/target.png";
import robImg from "@assets/imgs/rob.png";


import gameState from "@store/game";

import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";

function EnemyTeamCard({ team, players, close }) {
  function shoot(id, action) {
    gameState.shotInPlayer({id, action});
    close();
  }

  return (
    <div className="enemy-team">
      <h2 className="enemy-team_name">{team.name}</h2>
      <div className="enemy-team_list">
        {players.map((p) => {
          return (
            <div className="enemy-team_item">
              <div className="enemy-team_item-target">
                <img onClick={() => shoot(p._id, 'shot')}  className="enemy-team_item-target-img" src={targetImg} />
                <img onClick={() => shoot(p._id, 'rob')} className="enemy-team_item-target-img" src={robImg} />
              </div>
              <PlayerCard
                showName
                //   showPerks
                key={p._id}
                player={p}
                imgSize="g"
                classes="item"
                b={true}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}

function VictimChoice({ close, show }) {
  console.log("victim render");

  let { player } = playerState;
  let { players } = playersState;
  let { teams } = teamsState;

  let currentPlayer = players.find((p) => p._id == player._id);

  if (!currentPlayer) return null;

  function getPlayersTeam(teamId) {
    return players.filter((p) => p.team === teamId && p.alive);
  }

  let enemyTeams = teams.filter((t) => {
    return t._id !== player.team && getPlayersTeam(t._id).length !== 0;
  });

  return (
    <div>
      <Modal active={show} setActive={close}>
        <div
          style={{ backgroundImage: `url(${back})` }}
          className="choose-victum"
        >
          {enemyTeams.map((t) => (
            <EnemyTeamCard
              team={t}
              close={close}
              players={getPlayersTeam(t._id)}
            />
          ))}
        </div>
      </Modal>
    </div>
  );
}

export default VictimChoice;
