import React from "react";
import "./Finish.sass";
import Modal from "@components/UI/Modal/Modal";


import Content from "./Content/Content";
import gameState from "@store/game";

function Finish() {
  let { game } = gameState;
  let isFinish = game.status === "finish";

  return (
    <div className="finish-overlay">
      <Modal active={isFinish} setActive={() => {}}>
        {isFinish ? <Content game={game} /> : null}
      </Modal>
    </div>
  );
}

export default Finish;
