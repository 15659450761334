import React, { useEffect } from "react";
import "./Content.sass";
// import { Howl } from "howler";
import ReactHowler from "react-howler";
import winSample from "@assets/samples/win.mp3";

import MyButton from "@components/UI/MyButton/MyButton";

import teamState from "@store/teams";
import playerState from "@store/player";
import playersState from "@store/players";
import gameState from "@store/game";

import back from "@assets/imgs/win.jpg";
import crownImg from "@assets/imgs/crown.png";
import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";

function Content({ game }) {
  //   useEffect(() => {
  //     let sound = new Howl({
  //       src: [s],
  //     });
  //     sound.play();
  //   }, []);
  let { player, isAdmin } = playerState;
  let { players } = playersState;
  let { teams } = teamState;

  let team = teams.find((t) => t._id == game.winner);

  let teamPlayers = players.filter((p) => p.team == team._id);

  return (
    <div style={{ backgroundImage: `url(${back})` }} className="finish-content">
      <ReactHowler src={winSample} playing={true} />
      <img src={crownImg} className="crown-img" />
      <h2 className="winner-name">{team.name} </h2>
      <div className="players-list">
        {teamPlayers.map((p) => {
          return (
            <div>
              <PlayerCard
                showName
                //   showPerks
                key={p._id}
                player={p}
                imgSize="b"
                classes="item-win-card"
                showPrize
              />

              {p.personalPrize ? (
                <div>
                  <p className="prize-title">Личная награда</p>
                  <p className="prize-text"> {p.personalPrize}</p>
                </div>
              ) : null}

              {p.teamPrize ? (
                <div>
                  <p  className="prize-title">Командная награда</p>
                  <p className="prize-text"> {p.teamPrize}</p>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      {isAdmin ? (
        <MyButton
          name="finish-game"
          type="button"
          classes="finish-game"
          value="Завершить игру!"
          // disabled={submitDisabled()}
          handler={() => gameState.finishGame()}
        />
      ) : null}
    </div>
  );
}

export default Content;
