import React from "react";
import "./WaitGameAdmin.sass";
import MyButton from "@components/UI/MyButton/MyButton";
import Settings from "./Settings/Settings";
import Teams from "./Teams/Teams";

import gameState from '@store/game';

function WaitGameAdmin() {
  return (
    <div className="wait-game-admin-page">
      <MyButton
        handler={() => gameState.startGame()}
        name="start-game"
        large
        value="В бой!"
        classes="start-game"
      />
      <Teams />
      <Settings />
    </div>
  );
}

export default WaitGameAdmin;
