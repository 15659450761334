import { makeObservable, observable, action, computed } from "mobx";
import GameService from "@services/GameService";
import teamsState from "./teams";
import playersState from "./players";
import event from "../event";

import ApiError from "../exceptions/apiError";

class Game {
  loaded = false;
  game = null;
  status = null;
  duel = null;
  config = null;
  showDuel = false;

  constructor() {
    makeObservable(
      this,
      {
        showDuel: observable,
        loaded: observable,
        game: observable,
        config: observable,
        duel: observable,
        status: observable,
        setLoaded: action,
        setGame: action,
        getGame: action,
        createGame: action,
        setGameConfig: action,
        saveConfig: action,
        finishGame: action,
        shotInPlayer: action,
        protectPlayer: action,
        hideDuel: action,
      },
      { deep: true }
    );
  }

  setLoaded(loaded) {
    this.loaded = loaded;
  }

  setGame(game) {
    this.game = game;

    if (game) {
      if (game.status !== this.status) {
        this.status = game.status;
      }

      if (game.config) {
        this.config = game.config;
      }
      if (game.duel) {
        this.duel = game.duel;
        if (!this.showDuel) {
          this.showDuel = true;
        }
      }
    } else {
      this.status = null;
    }
  }

  hideDuel() {
    if (this.duel && this.duel.status === "finish") {
      this.showDuel = false;
    }
  }

  setDuel() {
    this.duel = null;
  }

  async getGame() {
    try {
      const { data } = await GameService.getGame();

      if (data) {
        this.setGame(data.game);
        playersState.setPlayers(data.players);
        teamsState.setTeams(data.teams);
       
      }
      this.setLoaded(true);
    } catch (e) {
      ApiError(e);
    }
  }

  async createGame() {
    try {
      await GameService.createGame();
    } catch (e) {
      ApiError(e);
    }
  }

  setGameConfig(action, value) {
    let config = this.config;

    let newConfig;
    if (action === "time") {
      newConfig = Object.assign({}, config, { gameTime: value });
    } else if (action === "max") {
      newConfig = Object.assign({}, config, { maxPlayers: value });
    } else if (action === "freq") {
      newConfig = Object.assign({}, config, { freqBot: value });
    } else if (action === "chance") {
      newConfig = Object.assign({}, config, { chanceBot: value });
    }

    this.config = newConfig;
  }

  async saveConfig() {
    try {
      await GameService.saveConfig(this.config);
    } catch (e) {
      ApiError(e);
    }
  }

  // setLoaded(loaded) {
  //   this.loaded = loaded;
  // }

  // get computedTimer() {
  //   if (this.game.duel && this.game.duel.status === "process") {
  //     let finished = this.game.duel.finished;
  //     function millisToSeconds(millis) {
  //       return ((millis % 60000) / 1000).toFixed(0);
  //     }
  //     let msc = new Date(finished) - new Date();

  //     let sec = millisToSeconds(msc);

  //     if (sec < 0) {
  //       return 0;
  //     } else {
  //       return sec;
  //     }
  //   } else {
  //     return 0;
  //   }
  // }

  // notHasDuel() {
  //   this.game = Object.assign(this.game, { hasDuel: false });
  // }

  // setGame(game) {
  //   this.game = game;
  // }

  async finishGame() {
    try {
      this.showDuel = false;
      this.duel = null;
      await GameService.finishGame();
    } catch (e) {
      ApiError(e);
    }
  }

  async startGame() {
    try {
      await GameService.startGame();
    } catch (e) {
      ApiError(e);
    }
  }

  async shotInPlayer(data) {
    try {
      await GameService.shotInPlayer(data);
    } catch (e) {
      ApiError(e);
    }
  }

  async protectPlayer() {
    try {
      await GameService.protectPlayer();
    } catch (e) {
      ApiError(e);
    }
  }
}

export default new Game();

// import { makeObservable, observable, action, computed } from "mobx";
// import GameService from "@services/GameService";
// import teamsState from "./teams-state";
// import playersState from "./players-state";

// import ApiError from "../exceptions/apiError";

// class Game {
//   loaded = false;
//   game = null;
//   config = {
//     gameTime: "nolimit",
//     maxPlayers: 18,
//     freqBot: 1,
//     chanceBot: 100,
//   };

//   constructor() {
//     makeObservable(
//       this,
//       {
//         loaded: observable,
//         game: observable,
//         setLoaded: action,
//         setGame: action,
//         fetchGame: action,
//         createGame: action,
//         getGame: computed,
//         getDuel: computed,
//         computedTimer: computed,
//         finishGame: action,
//         shotInPlayer: action,
//         getConfig: computed,
//         setGameConfig: action,
//         saveConfig: action,
//       },
//       { deep: true }
//     );
//   }

//   setGameConfig(action, value) {
//     if (action === "time") {
//       this.config = Object.assign(this.config, { gameTime: value });
//     } else if (action === "max") {
//       this.config = Object.assign(this.config, { maxPlayers: value });
//     } else if (action === "freq") {
//       this.config = Object.assign(this.config, { freqBot: value });
//     } else if (action === "chance") {
//       this.config = Object.assign(this.config, { chanceBot: value });
//     }
//   }

//   get getConfig() {
//     return this.config;
//   }

//   get getGame() {
//     return this.game;
//   }

//   get getDuel() {
//     return {
//       hasDuel: this.game.hasDuel,
//       duel: this.game.duel,
//     };
//   }

//   async saveConfig() {
//     try {
//       await GameService.saveConfig(this.config);
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   setLoaded(loaded) {
//     this.loaded = loaded;
//   }

//   get computedTimer() {
//     if (this.game.duel && this.game.duel.status === "process") {
//       let finished = this.game.duel.finished;
//       function millisToSeconds(millis) {
//         return ((millis % 60000) / 1000).toFixed(0);
//       }
//       let msc = new Date(finished) - new Date();

//       let sec = millisToSeconds(msc);

//       if (sec < 0 ) {
//         return 0;
//       } else {
//         return sec;
//       }
//     } else {
//       return 0;
//     }
//   }

//   notHasDuel() {
//     this.game = Object.assign(this.game, { hasDuel: false });
//   }

//   setGame(game) {
//     this.game = game;
//   }

//   async fetchGame() {
//     try {
//       const response = await GameService.fetchGame();
//       if (response.data) {

//         console.log(response.data, 'data game fetch')

//         this.setGame(response.data.game);

//         console.log("sds");
//         teamsState.setTeams(response.data.teams);
//         playersState.setPlayers(response.data.players);
//       }
//       this.setLoaded(true);
//       console.log("ser l");
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   async createGame() {
//     try {
//       await GameService.createGame();
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   async finishGame() {
//     try {
//       await GameService.finishGame();
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   async startGame() {
//     try {
//       await GameService.startGame();
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   async shotInPlayer(playerId) {
//     try {
//       await GameService.shotInPlayer(playerId);
//     } catch (e) {
//       ApiError(e);
//     }
//   }

//   async protectPlayer() {
//     try {
//       await GameService.protectPlayer();
//     } catch (e) {
//       ApiError(e);
//     }
//   }
// }

// export default new Game();
