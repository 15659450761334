import { makeObservable, observable, action } from "mobx";
import DataService from "@services/DataService";
import ApiError from "../exceptions/apiError";

class Data {
  data = [];

  constructor() {
    makeObservable(
      this,
      {
        data: observable,
        setData: action,
        fetchData: action,
      },
      { deep: true }
    );
  }
  setData(data) {
    this.data = data;
  }

  async fetchData() {
    try {
      const response = await DataService.fetchData();
      this.setData(response.data);
    } catch (e) {
      ApiError(e);
    }
  }
}

export default new Data();
