import React, { Fragment } from "react";
import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";

import Controls from "./Controls";

function Process({ duel, player1, player2 }) {
  return (
    <Fragment>
      <div className="duel-content_player">
        <PlayerCard
          showName
          player={player1}
          imgSize="g"
          classes="item"
          b={true}
        />
      </div>
      <Controls duel={duel} />
      <div className="duel-content_player">
        <PlayerCard
          showName
          player={player2}
          imgSize="g"
          classes="item"
          b={true}
        />
      </div>
    </Fragment>
  );
}

export default Process;
