import React, { Fragment } from "react";
import PlayerCard from "@components/blocks/PlayerCard/PlayerCard";
import golds from "@assets/imgs/golds.png";
import ReactHowler from "react-howler";

import moneySample from "@assets/samples/money.mp3";

function Result({ player, duelResult, prize }) {
  let resultText;
  let resultStyle;
  if (duelResult === "kill") {
    resultText = "Убит";
    resultStyle = "duel-content_result-red";
  } else if (duelResult === "miss") {
    resultText = "Промах";
    resultStyle = "duel-content_result-green";
  } else if (duelResult === "rob") {
    resultText = "Ограблен";
    resultStyle = "duel-content_result-red";
  } else if (duelResult === "failRob") {
    resultText = "Ограбление провалено";
    resultStyle = "duel-content_result-green";
  }

  let playMusicMoney = prize && (duelResult === "kill" || duelResult === "rob");

  return (
    <Fragment>
      <div className="duel-content_result">
        <p className={`duel-content_result-text ${resultStyle}`}>
          {resultText}
        </p>

        <PlayerCard
          showName
          player={player}
          imgSize="g"
          classes="item"
          b={true}
        />

        {prize ? (
          <div className="duel-content_result-prize">
            <img src={golds} />
            <p>{prize}</p>
          </div>
        ) : null}

        {playMusicMoney ? (
          <ReactHowler src={moneySample} playing={true} />
        ) : null}
      </div>
    </Fragment>
  );
}

export default Result;
